import React from "react";
import { Link } from "react-router-dom"; // Use react-router-dom for navigation
import styles from "./styles/News.module.css"; // Ensure the path is correct
import Layout from "./components/Layout"; // Ensure Layout component is properly set up

const About = () => {
  return (
    <div className={styles.container}>
      <Layout
        canonical="https://dr1f7r.me/about"
        title="Dr1f7r - Social Media Creator in Sydney Australia"
        description="Dr1f7r social media creator sharing lifestyle contents around the world. Explore exciting adventures, tasty eats, and lifestyle tips. Follow on TikTok, Instagram, Pinterest, and Facebook for more!"
        keywords="Dr1f7r, Social Media Creator Director"
      >
        <main className={styles.main2}>
          {/* Page Header */}
          <h1>Eat. Sleep. Dr1f7r.</h1>
          <h2>Love Food, Relax, and Ride with Me, Friends!</h2>
          <p>Dr1f7r is a dynamic social media creator known for sharing exciting content centered around food 🍔, relaxation 🛋️, and travel 🌍. With a unique focus on exploring new destinations and indulging in delicious meals, Dr1f7r inspires followers to embrace the joy of riding 🚴, relaxing 💤, and discovering new experiences. Follow Dr1f7r for engaging videos 🎥, reviews ⭐, and merchandise 👕 that celebrates the thrill of life. Connect with Dr1f7r on TikTok, Instagram, Pinterest, and Facebook for exclusive updates and insights into a lifestyle built around adventure, food, and relaxation!</p>
          <h3>

          Live the Dr1f7r Lifestyle 🌍

          </h3>

          {/* Introduction Section */}
          <p>
          Are you all about good food, lazy days, and thrilling adventures? Welcome to your new favorite spot! Whether you're indulging in the best bites, catching up on sleep, or riding to new horizons, Dr1f7r is all about enjoying life to the fullest 🍔💤🏍️ and all the fun you can handle! Let’s build a community where creativity, adventure, and fun thrive. 🚀
👉 Be sure to like, comment, and share your favorite posts to keep the vibe alive! 🔥
          </p>

          {/* Expertise Section */}
          <h3>
          Content for Creators & Dreamers ✨
          </h3>
          <p>
          Calling all content creators and dreamers! If you're looking for fresh, reusable content or inspiration, you've found the right place. 📸🎬 From stunning visuals to cool tips, my social channels are packed with resources to fuel your creativity.
Whether you're looking for epic riding videos or just want to get inspired, 🌟Make sure to check out my "Watzup" page for product reviews, recommendations, and so much more – helping creators like YOU succeed! 🛍️💬</p>

          {/* Experience Section */}
          <h3>
          Dr1f7r Merch is Here!
          </h3>
          <p>
          Want to wear your love for the Dr1f7r lifestyle? It’s here! 🚀 Check out my exclusive merch collection – designed with passion for those who live for food, relaxation, and exploration. T-shirts, hoodies, and more to show off your Dr1f7r pride. 🛒💖
Follow me on Instagram, Pinterest, and all my social channels for sneak peeks at new designs. Share your Dr1f7r gear and get featured on my feed! 📸✨</p>

          <h3>
          Let’s Connect & Create Together 🌟
          </h3>
          <p>
          It’s not just about creating – it’s about connecting. Good food, or just living life with a chill vibe, then you're part of the Dr1f7r team. 🌍💬
 Keep the conversation going by liking, commenting, and sharing what you love. 🤩 And don’t forget to check out my "Watzup" page for the latest reviews, recommendations, and product finds that help you thrive! 🛍️💡</p>


        </main>
      </Layout>
    </div>
  );
};

export default About;