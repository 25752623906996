import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./home";
import NewsDetails from "./watzup/[slug]";
import Watzup from "./watzup/index"; 
import About from "./about";
import Connect from "./connect";
import Shop from "./shop";
import NotFound from "./404";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/connect" element={<Connect />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/watzup" element={<Watzup />} />
        <Route path="/watzup/:slug" element={<NewsDetails />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
