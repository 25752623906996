import React from "react";
import styles from "../styles/Footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.footer}>

      <p className={styles.copy}>All Rights Reserved. Copyrights © Dr1f7r 2025 v.0.9</p>
    </footer>
  );
};

export default Footer;
