import React, { useEffect } from "react";
import styles from "../styles/News.module.css";
import Layout from "../components/Layout";

const Watzup = () => {
  useEffect(() => {
    // Dynamically load the TikTok embed script
    const script = document.createElement("script");
    script.src = "https://www.tiktok.com/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Layout
      title="Dr1f7r - Social Media Creator in Sydney Australia"
      description="Dr1f7r social media creator sharing lifestyle contents around the world. Explore exciting adventures, tasty eats, and lifestyle tips. Follow on TikTok, Instagram, Pinterest, and Facebook for more!"
      keywords="Dr1f7r, watzup, whatsup"
    > <main className={styles.main2}>
   
        <h1>Watzup!</h1>
   
        <blockquote
          className="tiktok-embed"
          cite="https://www.tiktok.com/@dr1f7r"
          data-unique-id="dr1f7r"
          data-embed-type="creator"
          style={{ Width: "100%", minWidth: "100%" }}
        >
          <section>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.tiktok.com/@dr1f7r?refer=creator_embed"
            >
              @dr1f7r
            </a>
          </section>
        </blockquote>
    </main>
    </Layout>
  );
};

export default Watzup;
