import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../styles/Header.module.css";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <header className={styles.header}>
      {/* Logo Section */}
      <div>
        <Link to="/" title="Home">
          <img
            itemProp="image"
            src="/dr1f7r-production.svg"
            alt="Dr1f7r produciton"
            width={600}
            height={160}
            loading="eager"
            className={styles.logo}
          />
        </Link>
      </div>

      {/* Navigation Menu */}
      <nav className={showMenu ? styles.show : styles.hide}>
        <ul className={styles.menu}>
        <li>
            <Link to="/about" title="About Dr1f7r">
            <img
                 itemProp="image"
                 src="/tiktok-gift-genius.svg"
                 alt="About Dr1f7r Social Media Content Creator"
                 width={60}
                 height={60}
                 loading="eager"
                 className={styles.butimg}
                  />
              <p className={styles.menubut}>Friend</p>
            </Link>
          </li>
      
          <li>
            <Link to="/watzup" title="Friends">
            <img
                 itemProp="image"
                 src="/tiktok-gift-sunglasses.svg"
                 alt="Watzup! Blog Articles Dr1f7r"
                 width={60}
                 height={60}
                 loading="eager"
                 className={styles.butimg}
                  />
              <p className={styles.menubut}>Watzup</p>
            </Link>
          </li>
          <li>
            <Link to="/shop" title="Dr1f7r Shop">
            <img
                 itemProp="image"
                 src="/tiktok-gift-finger-hearts.svg"
                 alt="Blog Articles Dr1f7r Social Media Content Creator"
                 width={60}
                 height={60}
                 loading="eager"
                 className={styles.butimg}
                  />
              <p className={styles.menubut}>Merch</p>
            </Link>
          </li>
          <li>
            <Link to="/connect" title="Connect with Dr1f7r">
            <img
                 itemProp="image"
                 src="/tiktok-gift-ricebowl.svg"
                 alt="Contact Dr1f7r Social Media Content Creator"
                 width={60}
                 height={60}
                 loading="eager"
                 className={styles.butimg}
                  />
              <p className={styles.menubut}>Contact</p>
            </Link>
          </li>
        </ul>
      </nav>

      {/* Hamburger Button */}
      <button
        className={styles.hamb}
        onClick={toggleMenu}
        id="d24menu"
        title="Toggle Menu"
      >
        {showMenu ? (
          <span className={styles.close}></span>
        ) : (
          <span className={styles.hambline}></span>
        )}
      </button>
    </header>
  );
};

export default Header;
