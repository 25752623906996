import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles/Home.module.css";
import Layout from "./components/Layout";
import { news } from "./data/watzup";

export default function Home() {
  // Get the latest 3 news items
  const latestNews = news.slice(0, 3);

  return (
 
      <div className={styles.container}>
        <Layout
          title="Dr1f7r - Social Media Creator in Sydney Australia"
          description="Dr1f7r social media creator sharing lifestyle contents around the world. Explore exciting adventures, tasty eats, and lifestyle tips. Follow on TikTok, Instagram, Pinterest, and Facebook for more!"
          keywords="Dr1f7r, Social Media Creator Director"
        >
          <main className={styles.main}>
              <Link to="/" title="Home">
             
                      <img
                        itemProp="image"
                        src="/dr1f7r-production.svg"
                        alt="Dr1f7r produciton"
                        width={800}
                        height={220}
                        loading="eager"
                        className={styles.Dr1f7r}
                      />
                    </Link>
            <h1 className={styles.homehead}>
              Eat. Sleep. Dr1f7r.
            </h1>
            <h2 className={styles.h2sub}>
            
                Love to eat yummys. Love to Sleep and being lazy. Love to Ride and explore the world. Come and Join me my Friends.
             
            </h2>

            <div className={styles.grid}>
              {/* Card Links */}
              {[
                {
                  to: "/about",
                  imgSrc: "/tiktok-gift-genius.svg",
                  imgAlt: "Dr1f7r",
                  title: "About Dr1f7r",
                  heading: "Friend",
                  text: "Get to know what I am about",
                },
                {
                  to: "/Watzup",
                  imgSrc: "/tiktok-gift-sunglasses.svg",
                  imgAlt: "Whats up",
                  title: "Whats up",
                  heading: "Watzup",
                  text: "Cool places and things review",
                },
                {
                  to: "/shop",
                  imgSrc: "/tiktok-gift-finger-hearts.svg",
                  imgAlt: "Dr1f7r Merch Shop",
                  title: " Dr1f7r Merch Shop",
                  heading: "Shop",
                  text: "Cool Merchandise has arrived",
                },
                {
                  to: "/connect",
                  imgSrc: "/tiktok-gift-ricebowl.svg",
                  imgAlt: "Contact",
                  title: "Contact",
                  heading: "Connect",
                  text: "Connect with me live lets party",
                },
              ].map((item, idx) => (
                <Link
                  to={item.to}
                  title={item.title}
                  className={styles.card}
                  key={idx}
                >
                  <img
                  
                    src={item.imgSrc}
                    alt={item.imgAlt}
                    width={160}
                    height={160}
                  />
                  <h2 className={styles.homeimg}>{item.heading}</h2>
                  <p className={styles.butsub}>{item.text}</p>
                </Link>
              ))}
            </div>
          </main>
 
        </Layout>
      </div>
  );
}
