import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import styles from "../styles/Home.module.css";
import { news as data } from "../data/watzup";
import Layout from "../components/Layout"; // Ensure Layout component is properly set up

const NewsDetails = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const currentPost = data.find((item) => item.slug === slug);

  if (!currentPost) {
    return (
      <Layout title="News Not Found" description="News not found in D24 Media whatzup">
        <h1>News Not Found</h1>
      </Layout>
    );
  }

  const previous = data.find((item) => item.id === Number(currentPost.id) - 1);
  const next = data.find((item) => item.id === Number(currentPost.id) + 1);

  return (
    <Layout
      title={currentPost.name}
      description={currentPost.summary}
      keywords={currentPost.keywords}
    >
      <div className={styles.news}>
        <div className={styles.description}>
          <h1>
            {currentPost.name}
          </h1>
        </div>
        {currentPost.image && (
          <div className={styles.image}>
            <img
              src={currentPost.image}
              alt={currentPost.name}
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        )}
        <span className={styles.date}>
          {currentPost.date} {currentPost.time}
        </span>
        <p dangerouslySetInnerHTML={{ __html: currentPost.detail }} />

        <div className={styles.pagination}>
          <button
            onClick={() => navigate(`/watzup/${previous?.slug}`)}
            disabled={!previous}
          >
            Previous
          </button>
          <button onClick={() => navigate("/watzup")}>whatzup</button>
          <button
            onClick={() => navigate(`/watzup/${next?.slug}`)}
            disabled={!next}
          >
            Next
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default NewsDetails;
